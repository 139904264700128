<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="ftb_table_section" id="ftb_md_table" v-else-if="tables">
        <div class="current_league_score_table">
            <table class="league_score_table">
                <thead>
                <tr>
                    <th>
                        #
                    </th>
                    <th>
                        {{ $t("Teams") }}
                    </th>
                    <th class="details_player_hide_mobile">
                        GP
                    </th>
                    <th>
                        W
                    </th>
                    <th class="details_player_hide_mobile">
                        D
                    </th>
                    <th>
                        L
                    </th>
                    <th class="details_player_hide_mobile">
                        GF
                    </th>
                    <th class="details_player_hide_mobile">
                        GA
                    </th>
                    <th class="details_player_hide_mobile">
                        GD
                    </th>
                    <th>
                        PTS
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr :class="[table.team.id == teams.home_team || table.team.id == teams.away_team ? 'current_teams_table' : '']" v-for="table in tables" :key="table.place">
                    <td>
                        <span class="highlight-border"></span><!-- [style.background-color]="color"-->
                        {{table.place}}
                    </td>
                    <td>
                      <div class="current_league_score_table_teamlogo">
                        <img class="details_player_hide_mobile" :onerror="$teamDefaultLogo()" :src="$storageURL+'images/football/'+table.team.id+'_90x90.webp'" alt="team logo">
                          {{table.team.name}}
                      </div>
                    </td>
                    <td class="details_player_hide_mobile">
                        {{table.played}}
                    </td>
                    <td>
                        {{table.won}}
                    </td>
                    <td class="details_player_hide_mobile" >
                        {{table.draws}}
                    </td>
                    <td>
                        {{table.lost}}
                    </td>
                    <td class="details_player_hide_mobile">
                        {{table.scored}}
                    </td>
                    <td class="details_player_hide_mobile">
                        {{table.conceded}}
                    </td>
                    <td class="details_player_hide_mobile">
                        {{table.difference}}
                    </td>
                    <td>
                        {{table.points}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'MatchDetailTable',
        data() {
            return {
                tables: null,
                teams: {
                  home_team: '',
                  away_team: ''
                }
            }
        },
        mounted() {
          this.getMatchDetail();
          this.getTableData();
        },
        serverPrefetch() {
          this.getMatchDetail();
            return this.getTableData();

        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            var replacements = {
                '%TOURNAMENT_NAME%': this.$store.state.fbMatchDetailResponse.data.tournament.name,
            };

            let title = this.$getSafe(() => this.$replacePlaceHolders(ss.football_match_table_meta_title,replacements));
            let description = this.$getSafe(() => this.$replacePlaceHolders(ss.football_match_table_meta_description,replacements));
            let keywords = this.$getSafe(() => ss.football_match_table_meta_keywords);

            let canonical = this.$toFullPath(this.$route.fullPath).replace('table', 'overview');

            return {
                title: title,
                description: description,
                meta: this.$getMetaTags(title, description, keywords),
                link:[
                    {rel: 'canonical', href: canonical}],
            };
        },
        methods: {
            getTableData() {
                this.is_data_ready = false;
                return this.$axios
                    .get(this.$FB_TABLE_API.replace(':match', this.$route.params.matchId))
                    .then(response => {
                        this.is_data_ready = true;
                        this.tables = response.data.table;
                    })
                    .catch(error => console.log(error))
            },
            getMatchDetail(){
              let teams = this.innings = this.$store.getters.getFbMatchDetailResponse.competitors;
              this.teams.home_team = teams.filter( (team) =>
                team.isHome == true
              )[0].id;
              this.teams.away_team = teams.filter( (team) =>
                team.isHome == false
              )[0].id;
            }
        }
    }

</script>
